"use strict";

module.exports = function () {
    $(".logout").click(function (e) {
        e.preventDefault();

        const that = this;
        gigya.accounts.logout({
            callback: function () {
                window.location.href = $(that).attr("href");
            }
        });
        
    });
};
